:local(.mainContiaber) {
  margin-bottom: 20px;
}
:local(.container) {
  display: flex;
  background-color: #fff;
}

:local(.noValue) {
  padding: 4px 0;
  cursor: pointer;
  color: #666;
}

:local(.addSeparator) {
  flex: 1;
}
