:local(.container) {
  background-color: #fff;
  border-right: 1px solid #eee;
  color: rgb(26, 26, 26);
  display: flex;
  flex-direction: column;
  width: 200px;
  composes: smallShadow from global;
}

@media (max-width: 767px) {
  :local(.container) {
    width: 100%;
  }
}

:local(.menuInner) {
  flex: 1;
  padding: 10px 0;
  overflow: auto;
  color: rgb(26, 26, 26);
  background: rgb(202, 202, 202);
  -webkit-overflow-scrolling: touch;
}

@media print {
  :local(.container) {
    display: none;
  }
}

:local(.title) {
  display: block;
  color: rgb(26, 26, 26);
  font-size: 20px;
  margin: 20px;
  font-weight: bold;
}

:local(.divider) {
  background-color: #fff;
  opacity: 0.1;
  height: 1px;
  width: 100%;
}

:local(.menuItem) {
  cursor: pointer;
  display: block;
  color: rgb(26, 26, 26);
  padding: 10px 20px;
  opacity: 0.7;
}

:local(.menuItem):hover {
  opacity: 1;
}

:local(.itemActive) {
  composes: menuItem;
  opacity: 1;
}

:local(.logout) {
  composes: menuItem;
  font-size: 14px;
  display: flex;
  align-items: center;
}

:local(.categoryTitle) {
  padding: 10px 19px;
  font-size: 14px;
  color: rgb(26, 26, 26);
}

:local(.categoryItems) {
  margin-bottom: 10px;
}

:local(.categoryTitleText) {
  margin-left: 4px;
  position: relative;
  top: 1px;
}
