:local(.container) {
  flex: 1;
  display: flex;
}

:local(.content) {
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 50px;
}
