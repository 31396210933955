:local(.container) {
  composes: content from global;
}

:local(.container) .divider {
  margin: 10px 0;
}

:local(.name) {
  font-weight: bold;
}

:local(.container) a {
  font-weight: bold;
  color: #111;
  text-align: center;
  display: block;
}

:local(.activeDate) {
  color: red;
}
