:local(.container) {
}

:local(.twoFactorActivatedText) {
  position: relative;
  margin-left: 10px;
}

:local(.twoFactorActivatedIcon) {
  position: relative;
  top: 7px;
}

:local(.yourAccountIsSecure) {
  margin-left: 38px;
  font-size: 14px;
}

:local(.googleIcon) {
  height: 66px;
  position: relative;
  margin-right: 10px;
  margin-bottom: 20px;
}

:local(.appStoreIcon) {
  height: 66px;
  margin-bottom: 20px;
}

:local(.instructionStep) {
}

:local(.addManually) {
  color: #777;
  font-size: 13px;
}

:local(.addManuallyPre) {
  font-size: 13px;
}

:local(.errorMessage) {
  color: red;
  margin-top: 15px;
}
