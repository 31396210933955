:local(.container) {
}

:local(.container) > div {
  display: block !important;
}

:local(.small) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:local(.expanded) {
}
