:local(.container) {
  position: relative;
}

:local(.navbar) {
  background: rgb(56, 55, 53);
  position: fixed;
  z-index: 1;
}

:local(.header) {
  display: flex;
}

:local(.title) {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: rgb(250, 250, 250);
  padding-right: 15px;
}

:local(.container) .orion-select__control,
:local(.container) .os-input-text,
:local(.container) .orion-select__single-value {
  background-color: rgb(56, 55, 53);
  color: rgb(250, 250, 250);
}

:local(.child) {
  flex: 1;
}
