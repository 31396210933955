:local(.container) {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  flex: 1;
}

:local(.multi) {
  composes: container;
  flex: none;
}

:local(.add) {
  flex: 1;
  display: flex;
}

:local(.label) {
  composes: buttonShadow from global;
  flex: 1;
  background: #fff;
  padding: 5px 6px;
  border-radius: 4px;
}

:local(.container) svg {
  margin-right: 10px;
}

:local(.loading) {
  padding: 5px 6px;
}

:local(.input) {
  display: none;
}
