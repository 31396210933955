:local(.container) {
  /* display: inline-flex;*/
  display: flex;
  flex: 1;
  background-color: #eee;
  border-radius: 4px;
  font-size: 14px;
  padding: 5px 6px;
  font-weight: bold;
}

:local(.multi) {
  composes: container;
  flex: none;
  margin-right: 5px;
}

:local(.name) {
  flex: 1;
}

:local(.delete) {
  margin-left: 4px;
  cursor: pointer;
}

:local(.loading) {
  composes: container;
}
