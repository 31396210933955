:local(.container) {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

:local(.content) {
  composes: shadow from global;
  width: 400px;
  padding: 40px 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

:local(.center) {
  text-align: center;
}

:local(.contentInner) {
  padding-left: env(safe-area-inset-left);
}

:local(.photo) {
  flex: 1;
  background-color: #eee;
  background-image: url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=80');
  background-size: cover;
  background-position: center;
}

:local(.logo) {
  text-align: center;
}

:local(.logo) img {
  height: 170px;
}

:local(.tos) {
  font-size: 14px;
}
