:local(.container) {
}

:local(.indicator) {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
:local(.indicatorContainer) {
  text-align: center;
}

:local(.container) .paginated-head-right {
  width: 0;
}
