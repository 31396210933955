:local(.image) {
  --webkit-user-drag: none;
  width: 100%;
}

:local(.notLoading) {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.4s ease;
  background: transparent;
  animation: loadingGlow 0.9s infinite;
}

:local(.loading) {
  composes: notLoading;
  background: #eee;
  opacity: 0.4;
}
