:local(.container) {
  composes: content from global;
  max-height: calc(100vh - 40px);
  overflow: auto;
  padding: 0px !important;
  width: 100%;
}

:local(.page) {
  position: relative;
  border-bottom: 2px solid #eee;
  box-sizing: content-box;
}

:local(.page):last-child {
  border-bottom: none;
  box-sizing: content-box;
}
