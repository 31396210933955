@import url(https://fonts.googleapis.com/css?family=Titillium+Web:400,600);
._1Pe88cc7YPveUosMO1aPXt {
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  transition: all 500ms cubic-bezier(0.9, 0, 0.1, 1);
}

.cWkvtuqbIY3YgLSInQd9g {
  background-color: rgba(0, 0, 0, 0);
}

.ZpOdZWhzJlOUsvrmZYtJg {
  background-color: rgba(0, 0, 0, 0.9);
}

._4btP1_YyeSohQ0EGdTe3g {
  background-color: rgba(0, 0, 0, 0);
}

._2gqJmnFDbzP4mEnDnpw_np {
  position: relative;
  background-color: #fff;
  box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  transition: all 500ms cubic-bezier(0.9, 0, 0.1, 1);
  position: relative;
  max-height: 90%;
  margin: 10px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-width: 350px;
}

._579bjAONM2v94ep2xQy6u {
  opacity: 0;
  top: -60%;
}

._2pkKAKreJ59xbXP-uVN_8s {
  opacity: 1;
  top: 0;
}

._1CGheDM7RkVdYKBrzuvyO_ {
  opacity: 0;
  top: 100%;
}

._1KKnPGwRbaM1KnC895kAal {
  padding: 20px;
}

._3TnHac-xrtCb4h7xfoalOp {
  font-size: 16px;
}

.DRAW53Rppfxvi5e0rxoVC {
  color: #999;
  font-size: 14px;
}

.OBI_iRJzf9M38RWQ2qh47 {
  padding: 20px 0;
}

._1MLKZS8hy8sdr_kmLCMty5 {
  position: absolute;
  right: 10px;
  top: 10px;
}

._1MLKZS8hy8sdr_kmLCMty5 .os_iconButton {
  color: #ccc;
}

._2KMpxbIJeQz036jY8tzTq8 {
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  background: #fff;
  transition: all 0.5s ease;
}

._3xyKUXp3rmu8ypI7UaA-66 {
  border-color: #0069ff;
}

._3Vhdoe5QaDAmqyj1rdiudn {
  height: 30px;
  outline: none;
  width: 26px;
  margin: 5px;
  padding: 0px 0;
  text-align: center;
  font-size: 20px;
  font-family: inherit;
  font-weight: bold;
  border: none;
  border-bottom: solid 1px #ddd;
  transition: all 0.5s ease;
  color: transparent;
  text-shadow: 0 0 0 #111;
}

._3Vhdoe5QaDAmqyj1rdiudn:focus {
  border-color: #0069ff;
  outline: none;
}

._3Vhdoe5QaDAmqyj1rdiudn:active {
  border-color: #ddd;
}

._3Vhdoe5QaDAmqyj1rdiudn::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ddd;
}

._3Vhdoe5QaDAmqyj1rdiudn::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ddd;
}

._3Vhdoe5QaDAmqyj1rdiudn::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ddd;
}

._1IanH3JtoS1t3JbmkqqCFf {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

._2lM31GEQm6exS7ho25lMwA {
  font-size: 30px;
  padding: 20px 0;
  font-weight: bold;
  background: #fff;
}

.jzfEshHM3Bth_yUDK13tM {
  padding: 20px 0;
  background: #eee;
  -webkit-flex: 1 1;
          flex: 1 1;
}

._3cmzC0CJe5iGShtXddJBUP {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

._2O7zANY6chyqPookClvD9i {

}

.QGr-K4ZInm1N4H07rB159 {

}

._1byUcl-egdQLo3M26MFhXd {
  border-top: 1px solid #eee;
  padding-top: 40px;
  margin-bottom: 20px;
  margin-top: 40px;
}

.LF052UF2WminU4urR636 {
  margin-bottom: 20px;
  margin-top: 20px;
}

._1H65yg3THEVidJVn3uJ9Dv {
  margin-bottom: 10px;
}

._1mjK5EgSsqv3B2HTUg0WxM {
  color: #7d7d7d;
}

.UoHibhVmjP04H4GAnfpGP {
  margin-top: 40px;
}

._1HaZrHP9jTSXzybt7M3IBb {
  margin-top: 40px;
}

._13EtqHRDNM1whHA2iG2m8e {
  margin-bottom: 20px;
}
._23QAr-DXfkur41JXGntH4x {
  box-sizing: border-box;
  font-size: 16px;
  font-family: inherit;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  padding: 6px 10px;
  display: -webkit-flex;
  display: flex;
  background-color: #fff;
}

._2qJZ0fkZLdhaJgJpyzDvXk {
  padding: 4px 0;
  cursor: pointer;
  color: #666;
}

._39mhOt0muOiKRszpHilh5U {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.YhH6777r6kJyh0a0lFEgr {
  /* display: inline-flex;*/
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  background-color: #eee;
  border-radius: 4px;
  font-size: 14px;
  padding: 5px 6px;
  font-weight: bold;
}

._16r0IoGh8WjQCvN5tQWQCz {
  -webkit-flex: none;
          flex: none;
  margin-right: 5px;
}

._2xWE12nFUJdEYMBSvwNnqs {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.M6_CoSdhBIg1S1-sV9MM_ {
  margin-left: 4px;
  cursor: pointer;
}

._1_i1XdjJTO3tmuNwbOu11C {
}

.NtxXeLYrkNvMiPCKaSnBj {
  display: -webkit-flex;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex: 1 1;
          flex: 1 1;
}

._2JWksj2TjP-_AvGUvc8454 {
  -webkit-flex: none;
          flex: none;
}

._3ZnCF8lm-FUmjkmGZ4ytWA {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
}

.ApUxS8F5JUuT6KTL1PDgT {
  -webkit-flex: 1 1;
          flex: 1 1;
  background: #fff;
  padding: 5px 6px;
  border-radius: 4px;
}

.NtxXeLYrkNvMiPCKaSnBj svg {
  margin-right: 10px;
}

._1Wa2hbbKU-yj0uEZ_rFaHh {
  padding: 5px 6px;
}

._1QotrRDgOrncucqMn1guiu {
  display: none;
}

._3kqenW1Awo3vRoe0VDtJan {
  margin-bottom: 10px;
}

._2MbEOkAmWaBVypcRZNMZc6 {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 10px;
}

._2085Bp0EEVEqfadPBp4OYh {
  -webkit-flex: 1 1;
          flex: 1 1;
  height: 46px;
}

._2-Zo3wt6PzjCY9ok82lA6d {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-left: 10px;
}

._2llJjQtIaE6dqTwautbYv5 {
  padding-bottom: 10px;
}

._2txpVolr0MI7SC9GwOJeyu {
  margin-left: -10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 20px;
}

._2-dovRZ1RjO8iiiPzB6mRB {
  -webkit-flex: 1 1;
          flex: 1 1;
  font-size: 25px;
  color: #000;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

._2-dovRZ1RjO8iiiPzB6mRB a {
  color: #111;
  border-radius: 10px;
  padding: 5px 10px;
}

._2-dovRZ1RjO8iiiPzB6mRB .last {
  border-radius: 10px;
  padding: 5px 10px;
}

._2-dovRZ1RjO8iiiPzB6mRB a:hover {
  background: #f3f3f3;
}

._1TBd2BEekI5LjR9JiBcX2V {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

._18LXVBsd3RwZxH8IPrTRM9 {

}

._2GNenx6mrIkScmkFb5wTrQ {

}

._2ZNq_xiqqA2D8dSJ0HpPXV {
  height: 1px;
  background: #eee;
  margin: 20px 0;
}

.jjGiD0AJHTYBceAZ8kdJO {
  color: red;
  margin-bottom: 24px;
}

._1mwo9r2p2c-W6LgdbRsMPx {

}

._3fs0dTzaXjjN3mSvaxUpp3 {
}

._2uUSBZzGLog3qyu_JDOMpP {
  position: relative;
  margin-left: 10px;
  font-size: 18px;
}

._1_MlLFZmKnHKQsKLV5jGoE {
  position: relative;
  top: 7px;
}

.AMd8JvFd8a6wuinNc491s {
  margin-left: 38px;
  font-size: 14px;
}

._3tZI8K1DPRbZYaBqUqkKsu {
  height: 66px;
  position: relative;
  top: -2px;
  margin-right: 10px;
  margin-bottom: 20px;
}

._207hUMuZF2jUSSwFcpZcMc {
  height: 70px;
  margin-bottom: 20px;
}

._2kLLcCwPEkzq_YjgrvzEln {
  font-size: 18px;
}

._3SHYHil3MhDa1fRiKy6A_w {
  color: #777;
  font-size: 13px;
}

._2mvhEp6O5jIJM1pvuBCVSY {
  font-size: 13px;
}

._1OwaDoC-SNOymhFGXQ__ms {
  color: red;
  margin-top: 15px;
}

._378S7BY4kj40oKBTJKbWVs {
}

._1x2lufvRSjuzBdX9azhj3Q {
  position: relative;
  margin-left: 10px;
}

._33Jpv0oB20ytmdPXIINJMf {
  position: relative;
  top: 7px;
}

._3_1hyyfLEg5qZ9HdB9Y7Ru {
  margin-left: 38px;
  font-size: 14px;
}

._2LgBx2S5lYu8B23KFaug9r {
  height: 66px;
  position: relative;
  margin-right: 10px;
  margin-bottom: 20px;
}

._3N3YetBwezQ8KhfcD-0-zL {
  height: 66px;
  margin-bottom: 20px;
}

.YWBCga9myPC1LzrkUu7S8 {
}

._2MwZK0fzxaf51jCWGZGwCt {
  color: #777;
  font-size: 13px;
}

.wN5DyNnKJOl0o9Ax7YeyY {
  font-size: 13px;
}

._1fKOVU8JWmPqXd26hZpTpT {
  color: red;
  margin-top: 15px;
}

._3wlSh5XNZ83-eLZNF37Vi2 {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.rS2OTDjndsLAtvH2tYEjX {
  width: 400px;
  padding: 40px 20px;
  background-color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.b0SnXLqyPGEto1y2CB8_9 {
  text-align: center;
}

._1cNOO6_8DKkto7rX5WLpW4 {
  padding-left: env(safe-area-inset-left);
}

._39B6vvgbakuR3i5O4kw8vJ {
  -webkit-flex: 1 1;
          flex: 1 1;
  background-color: #eee;
  background-image: url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=80');
  background-size: cover;
  background-position: center;
}

._3K0vEIcjXCV9jHubB9Lx4b {
  text-align: center;
}

._3K0vEIcjXCV9jHubB9Lx4b img {
  height: 170px;
}

._3Jyn_TD7snrLWTGm6KqUBZ {
  font-size: 14px;
}

.uQJ1pkeaRjy29_TMYpZb8 {
}

._3z4RuanWn6ZeIv7HtVPkRu {
  max-width: 100%;
}

._30efmoj6vxuADoVhWOiSy_ {
  background-color: #fff;
  border-right: 1px solid #eee;
  color: rgb(26, 26, 26);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 200px;
}

@media (max-width: 767px) {
  ._30efmoj6vxuADoVhWOiSy_ {
    width: 100%;
  }
}

._2TRyM8znIhFYlTWjXpzZVk {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 10px 0;
  overflow: auto;
  color: rgb(26, 26, 26);
  background: rgb(202, 202, 202);
  -webkit-overflow-scrolling: touch;
}

@media print {
  ._30efmoj6vxuADoVhWOiSy_ {
    display: none;
  }
}

._3yAeRwcqXrWvisVOjA5TjA {
  display: block;
  color: rgb(26, 26, 26);
  font-size: 20px;
  margin: 20px;
  font-weight: bold;
}

._2LjkyE4JnOAluHWOL-XyMd {
  background-color: #fff;
  opacity: 0.1;
  height: 1px;
  width: 100%;
}

._38XMrD1jCKUtd-91TTIkX5 {
  cursor: pointer;
  display: block;
  color: rgb(26, 26, 26);
  padding: 10px 20px;
  opacity: 0.7;
}

._38XMrD1jCKUtd-91TTIkX5:hover {
  opacity: 1;
}

.kXJAYHQz5el4_4HkHECEU {
  opacity: 1;
}

._17y0hEXDx2sDTwXmVe08y9 {
  font-size: 14px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

._3LT4uoxRwusv4hX1ONzvx2 {
  padding: 10px 19px;
  font-size: 14px;
  color: rgb(26, 26, 26);
}

._3Ft8MzGsU_vyaQpGn983i9 {
  margin-bottom: 10px;
}

._13VXVNTIMO8m-wgg9X2yAp {
  margin-left: 4px;
  position: relative;
  top: 1px;
}

._2fDGWWjugl4fShbPVNWeZz {
  display: none;
  cursor: pointer;
  padding: 10px 18px;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 767px) {
  ._2fDGWWjugl4fShbPVNWeZz {
    display: block;
  }
}

._1BF9S-L0x_Gf73KO5PVPwK {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
}

.G4_nAIOeHoHqCAgTGK4Zi {
  -webkit-flex: 1 1;
          flex: 1 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 50px;
}

._3dzszu0edT56nvw27R-vYR {
  background-color: #eee;
  border-radius: 5px;
}

.mFwpwy4BQNBtcnzzGLDDO {

}

.ZtUfGm0X2REe0s6fAwCla {
  margin-top: 40px;
}

._3FHUid9K3wvBmQPunvOzuW {
}

._1I9lhD2lX6PTN3NalOdMhf {
  font-size: 18px;
}

.FiMf2ywZ_mJSQxBAhHjNQ {
  font-size: 14px;
  margin-bottom: 10px;
}

.nPuNIRxmrcQsdANch_LvA {
  font-size: 14px;
  color: #999;
}

._1VNELUloXu0T6VqX7YURlF {
  margin-bottom: 22px;
}

.oyFjlUPHerVDuAl-KxUeW {
}

._5kaSce2N6i8dMfI9fu4Ri {
  float: right;
  background: #eee;
  border-radius: 4px;
  padding: 2px 5px;
  display: inline-block;
}

._1lT427BRxQXE7ex1lDC6_- {
}

._3lgbbHdBTr0Zqncr3Ccy1O {
  margin-top: 15px;
  color: red;
}

._1Ut-qRO-TH-Lyd1xMaMcmt {

}

.A4twqyOhbZRkNAwDMbYz7 {

}

._2fvnoGdOmiiRMk8Uwk7E5- {

}

._2KD6IIFnvZOPp926YFweqA {

}

._8i-xhs723to6H3kADJndK {
}

._1H_fKGwXTZdLQ8FflC68Ur {
  text-align: center;
  margin-top: 20px;
  padding: 40px;
}

._1A6V89jCLGALyQPCR1eYBB {
  margin-bottom: 20px;
}
._32s_JzdcbzcOfHY80BPOWE {
  display: -webkit-flex;
  display: flex;
  background-color: #fff;
}

._35Lnj8lbbheG3JV6cHMS2Y {
  padding: 4px 0;
  cursor: pointer;
  color: #666;
}

._1XvRel5z8wzHL7YtsAkrji {
  -webkit-flex: 1 1;
          flex: 1 1;
}

._1hYfcdqIK_Fsm4omHBoZu9 {
  /* display: inline-flex;*/
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  background-color: #eee;
  border-radius: 4px;
  font-size: 14px;
  padding: 5px 6px;
  font-weight: bold;
}

._19sKtq3EfwzMgtWqs9WBb- {
  -webkit-flex: none;
          flex: none;
  margin-right: 5px;
}

._2IgI4OzBKSSJZlhEdQte2w {
  -webkit-flex: 1 1;
          flex: 1 1;
}

._2gM-eRGqxh2-r8oXZWM0FD {
  margin-left: 4px;
  cursor: pointer;
}

.Xv3bzmzjN-vAymJgoqFZU {
}

._1q9Vzro-w5muYdtafGNuyI {
  display: -webkit-flex;
  display: flex;
  font-weight: bold;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.IcKfwLZ5XXGwIEClsUP-g {
  -webkit-flex: none;
          flex: none;
}

._1qe_d7u0Yy4UdTAWK_ljPS {
  margin-top: 30px;
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
}

.x-qNi_zFAowx8Lwz88fME {
  -webkit-flex: 1 1;
          flex: 1 1;
  background: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

._1q9Vzro-w5muYdtafGNuyI svg {
  margin-right: 10px;
}

._1x-IxvjPwSjINSNWIs_3It {
  padding: 5px 6px;
}

._2w3qkhN6UowBoESzZ06PAO {
  display: none;
}

.B90ooirc6mFl1_UkZjmEp {
  -webkit-flex: 1 1;
          flex: 1 1;
  font-weight: normal;
  text-align: center;
  margin-top: 20px;
  padding: 40px;
}

._2xpN5b8IRLKz55eTyG7XUT {
}

._1DILXmW1e6w4En40HLLKKY {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
._1NLLvay_Pl4IxWAfjw1vOM {
  text-align: center;
}

._2xpN5b8IRLKz55eTyG7XUT .paginated-head-right {
  width: 0;
}

._2u3ww3_-lFqK5OspsE-NVT {
}

._1kFHDdHsDWQhU91g6gCNQ3 {

}

._3eQWuyl9y6jo7uk_blbErA {
  position: relative;
}

._3T9vKAPFDlIccofvju5pTx {
  background: rgb(56, 55, 53);
  position: fixed;
  z-index: 1;
}

._17XwCyy__jjIpVaIzTpVZr {
  display: -webkit-flex;
  display: flex;
}

.qnKLEHKT-qWz8GQqAU8zh {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: rgb(250, 250, 250);
  padding-right: 15px;
}

._3eQWuyl9y6jo7uk_blbErA .orion-select__control,
._3eQWuyl9y6jo7uk_blbErA .os-input-text,
._3eQWuyl9y6jo7uk_blbErA .orion-select__single-value {
  background-color: rgb(56, 55, 53);
  color: rgb(250, 250, 250);
}

._1BZIiMYojScuDPAaY-Ivzc {
  -webkit-flex: 1 1;
          flex: 1 1;
}

._2fHHrB3mwL8NAKlH4MQDil {

}

._3HQTXfg2RjgEP0gRLN4FT0 {
  max-height: calc(100vh - 40px);
  overflow: auto;
  padding: 0px !important;
  width: 100%;
}

._2twWWKYT2vh1j9PnPBt6nd {
  position: relative;
  border-bottom: 2px solid #eee;
  box-sizing: content-box;
}

._2twWWKYT2vh1j9PnPBt6nd:last-child {
  border-bottom: none;
  box-sizing: content-box;
}

._3xbcTSbFO6JVfGVJwlPZXd {
  --webkit-user-drag: none;
  width: 100%;
}

._18Q1672xEsePKdMyUzpRAi {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.4s ease;
  background: transparent;
  -webkit-animation: loadingGlow 0.9s infinite;
          animation: loadingGlow 0.9s infinite;
}

._3kCedMnToR6UWc_9lAm6XN {
  background: #eee;
  opacity: 0.4;
}

._3frgOxvXe6dT6oFomJeObO {

}

.trHrzMCeSrbnW3p0MEiES {
  margin-bottom: 30px;
}

._1qdtOkhHKZ6cr40Ja_xOBO {
  display: -webkit-flex;
  display: flex;
  padding: 3px 0;
}

._1qdtOkhHKZ6cr40Ja_xOBO > div:first-child {
  -webkit-flex: 1 1;
          flex: 1 1;
  white-space: nowrap;
}

._1qdtOkhHKZ6cr40Ja_xOBO > div:last-child {
  display: block;
  margin-left: 20px;
  overflow: hidden;
}

._2j8Ly78kmlYSvw5fNWqsO7 {
  font-weight: bold;
  margin-bottom: 10px;
}

._3NAVSQfUNyridjS2hWNKNr {
}

._3NAVSQfUNyridjS2hWNKNr > div {
  display: block !important;
}

._23P1qkmpHbHBov-WUWbsZ- {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

._2y1xVcvZztluNZw6HWPAPk {
}

._3X6OkWaTdRidBrq0vnNXym {

}

._3GidXMV0XPvqI_SsAs_4yv {
  padding-bottom: 30px;
}

._3GidXMV0XPvqI_SsAs_4yv .os-tabs-inner {
  padding: 0;
}

._2w1wT56cz6pxUDOaDUtXB0 {

}

._28NX6_EQTrDsz2lbzoPRRO {
  margin-top: 20px;
}

.bAzdS-NfupmqrXohdRKp_ {
  display: block;
  color: #999;
  text-align: center;
}

._1HUOQ9CB1NVKyjmNyuSAyi {
}

._1HUOQ9CB1NVKyjmNyuSAyi .divider {
  margin: 10px 0;
}

.BRRUKvBOir7DC8xyQ3X3s {
  font-weight: bold;
}

._1HUOQ9CB1NVKyjmNyuSAyi a {
  font-weight: bold;
  color: #111;
  text-align: center;
  display: block;
}

._37DJydRavLPdlNUMcNEIgF {
  color: red;
}

._3vI49Kqbv7eVsQk21ajoO7 {
  text-align: center;
}

._1_yiYMIFRuXF37fAuefYUh {

}

._3TKZa0VEAeym_IHQX79JY7 {

}

.sLCVqB7lTBO023gDr0_J- {

}

._3KJdmGV1z13RpGq_LNP92_ {

}

._2QGhK3rcoWx5E2wCcC2gsP {

}

._32rmrE2_F0TOFX_DPJq-gy {

}

._2Ct9uGHg3mqqjbUAswyW9r {

}

.nkZAtF8pFGOFA7YKNhkMA {

}

.JnFNEUaOvA6gm6Y4uAnRH {

}

.fMbR1oD6AalCifKOBhttG {

}

._1K2U6zdaX3_o4Zr9xnTOPM {

}

._2OYtd6hDC0fVV9_GIB0y24 {

}

._1ZQsfuM8PRF36g0F0s83H6 {

}

._2YwyR21nn196BgxRisdVEk {

}

.-RmxPhQGso7OOtro0yu_N {

}

._2q0h-WU-phLg4KmRXWNlHY {

}

._3H0OvpNse4qur3nEGlDaj {

}

._2693cHko7S967lE_3kFf3Y {

}

._2aJi9TbZKOL-CvXc-MaSFD {

}

._1cDi5Of3FbObIl79gAHeQJ {

}

._1RkT11lT7Y2ojdB4up3UPb {

}

._25TkZgHMQ0eNoo9u0-_x4g {

}

._1KD9tJlO_ur9mtJTkSXj5K {

}

._1gloMRPFbdmduWbS5hLqSJ {
}

._1ehFLZ7IcK2xH2Vw3nO2OZ {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
._1v7goiPLCqK0zGA7JxSrPe {
  text-align: center;
}

._1gloMRPFbdmduWbS5hLqSJ .paginated-head-right {
  width: 0;
}

._1tbUkXr_gwWGGz-AEbiuV9 {
}

.U2zF1ode-pPyGkBnOnjsL {

}

.paginated-pagination {
  display: -webkit-flex;
  display: flex;
  margin: 20px 10px 10px 10px;
}
.paginated-pagination-pages {
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: right;
}
.paginated-pagination-select {
  position: relative;
  top: -2px;
  font-family: inherit;
  background-color: #eee;
  outline: none;
}
.paginated-pagination-page-input-container {
  display: inline-block;
  position: relative;
  top: -7px;
}
.paginated-pagination-page-input {
  background-color: #eee;
  border-radius: 5px;
  height: 30px;
  width: 40px;
  font-size: 16px;
  border: 0;
  text-align: center;
  outline: none;
}
.paginated-pagination-page-icon {
  display: inline-block;
  position: relative;
  top: -6px;
  cursor: pointer;
  color: #000;
}
.paginated-pagination-page-icon-disabled {
  display: inline-block;
  position: relative;
  top: -6px;
  color: #ddd;
}
.paginated-error {
  background-color: #eee;
  border-radius: 10px;
  text-align: center;
}
.paginated-error-icon {
  color: #666;
  margin: 0 auto;
  padding: 60px 0 10px 0;
}
.paginated-error-icon svg {
  height: 80px;
  width: 80px;
}
.paginated-error-message {
  color: #666;
  font-size: 16px;
  padding: 10px 0 60px 0;
}
.paginated-loading {
  text-align: center;
}
.paginated-head-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 20px;
}
.paginated-head-left {
  margin-right: 10px;
}
.paginated-head-title {
  font-size: 30px;
  font-weight: 700;
}
.paginated-head-center {
  -webkit-flex: 1 1;
          flex: 1 1;
  min-height: 48px;
}
.paginated-head-right {
  margin-left: 10px;
  text-align: right;
  width: 300px;
}
.paginated-head-right > form {
  width: 100%;
}
.paginated-table {
  padding: 10px;
  border-radius: 5px;
}
.paginated-table th {
  position: relative;
}
.paginated-th-sort {
  cursor: pointer;
}
.paginated-table-row {
  cursor: pointer;
}
.paginated-table-row.selected {
  background-color: #eee;
  cursor: pointer;
}
.paginated-sort {
  position: absolute;
  right: 10px;
  top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.paginated-sort-arrow-up {
  color: #888;
  margin: -8px;
  margin-bottom: 0px;
}
.paginated-sort-arrow-up.active {
  color: #000000;
}
.paginated-sort-arrow-down {
  color: #888;
  margin: -8px;
}
.paginated-sort-arrow-down.active {
  color: #000000;
}
.paginated-text-field {
  width: 100%;
}
.paginated-text-field-input {
  width: 100%;
  box-sizing: border-box;
  font-size: 20px;
  font-family: inherit;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  padding: 10px;
  outline: none;
}
.paginated-text-field-error {
  color: #ff3030;
  margin-top: 10px;
}

body {
  margin: 0;
  padding: 0;
  background: rgb(228, 228, 228);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

* {
  box-sizing: border-box;
}

.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  padding-right: 2rem;
  padding-left: 2rem;
}

.row {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.row.reverse {
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.col.reverse {
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  box-sizing: border-box;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.col-xs {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-basis: 0;
          flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  -webkit-flex-basis: 8.333%;
          flex-basis: 8.333%;
  max-width: 8.333%;
}

.col-xs-2 {
  -webkit-flex-basis: 16.667%;
          flex-basis: 16.667%;
  max-width: 16.667%;
}

.col-xs-3 {
  -webkit-flex-basis: 25%;
          flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  -webkit-flex-basis: 33.333%;
          flex-basis: 33.333%;
  max-width: 33.333%;
}

.col-xs-5 {
  -webkit-flex-basis: 41.667%;
          flex-basis: 41.667%;
  max-width: 41.667%;
}

.col-xs-6 {
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  -webkit-flex-basis: 58.333%;
          flex-basis: 58.333%;
  max-width: 58.333%;
}

.col-xs-8 {
  -webkit-flex-basis: 66.667%;
          flex-basis: 66.667%;
  max-width: 66.667%;
}

.col-xs-9 {
  -webkit-flex-basis: 75%;
          flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  -webkit-flex-basis: 83.333%;
          flex-basis: 83.333%;
  max-width: 83.333%;
}

.col-xs-11 {
  -webkit-flex-basis: 91.667%;
          flex-basis: 91.667%;
  max-width: 91.667%;
}

.col-xs-12 {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-1 {
  margin-left: 8.333%;
}

.col-xs-offset-2 {
  margin-left: 16.667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.333%;
}

.col-xs-offset-5 {
  margin-left: 41.667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.333%;
}

.col-xs-offset-8 {
  margin-left: 66.667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.333%;
}

.col-xs-offset-11 {
  margin-left: 91.667%;
}

.start-xs {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
}

.end-xs {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.middle-xs {
  -webkit-align-items: center;
  align-items: center;
}

.bottom-xs {
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.around-xs {
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.between-xs {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.first-xs {
  -webkit-order: -1;
  order: -1;
}

.last-xs {
  -webkit-order: 1;
  order: 1;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 49rem;
  }

  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12 {
    box-sizing: border-box;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-sm {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    -webkit-flex-basis: 8.333%;
            flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-sm-2 {
    -webkit-flex-basis: 16.667%;
            flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-sm-3 {
    -webkit-flex-basis: 25%;
            flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-flex-basis: 33.333%;
            flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-sm-5 {
    -webkit-flex-basis: 41.667%;
            flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-sm-6 {
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-flex-basis: 58.333%;
            flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-sm-8 {
    -webkit-flex-basis: 66.667%;
            flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-sm-9 {
    -webkit-flex-basis: 75%;
            flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-flex-basis: 83.333%;
            flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-sm-11 {
    -webkit-flex-basis: 91.667%;
            flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-sm-12 {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-1 {
    margin-left: 8.333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.667%;
  }

  .start-sm {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }

  .middle-sm {
    -webkit-align-items: center;
    align-items: center;
  }

  .bottom-sm {
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }

  .around-sm {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .between-sm {
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .first-sm {
    -webkit-order: -1;
    order: -1;
  }

  .last-sm {
    -webkit-order: 1;
    order: 1;
  }
}

@media only screen and (min-width: 64em) {
  .container {
    width: 65rem;
  }

  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    box-sizing: border-box;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-md {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    -webkit-flex-basis: 8.333%;
            flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-md-2 {
    -webkit-flex-basis: 16.667%;
            flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-md-3 {
    -webkit-flex-basis: 25%;
            flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-flex-basis: 33.333%;
            flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-md-5 {
    -webkit-flex-basis: 41.667%;
            flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-md-6 {
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-flex-basis: 58.333%;
            flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-md-8 {
    -webkit-flex-basis: 66.667%;
            flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-md-9 {
    -webkit-flex-basis: 75%;
            flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-flex-basis: 83.333%;
            flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-md-11 {
    -webkit-flex-basis: 91.667%;
            flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-md-12 {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-1 {
    margin-left: 8.333%;
  }

  .col-md-offset-2 {
    margin-left: 16.667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.333%;
  }

  .col-md-offset-5 {
    margin-left: 41.667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.333%;
  }

  .col-md-offset-8 {
    margin-left: 66.667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.333%;
  }

  .col-md-offset-11 {
    margin-left: 91.667%;
  }

  .start-md {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
  }

  .end-md {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }

  .middle-md {
    -webkit-align-items: center;
    align-items: center;
  }

  .bottom-md {
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }

  .around-md {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .between-md {
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .first-md {
    -webkit-order: -1;
    order: -1;
  }

  .last-md {
    -webkit-order: 1;
    order: 1;
  }
}

@media only screen and (min-width: 75em) {
  .container {
    width: 76rem;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    box-sizing: border-box;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-lg {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    -webkit-flex-basis: 8.333%;
            flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-lg-2 {
    -webkit-flex-basis: 16.667%;
            flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-lg-3 {
    -webkit-flex-basis: 25%;
            flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-flex-basis: 33.333%;
            flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-lg-5 {
    -webkit-flex-basis: 41.667%;
            flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-lg-6 {
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-flex-basis: 58.333%;
            flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-lg-8 {
    -webkit-flex-basis: 66.667%;
            flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-lg-9 {
    -webkit-flex-basis: 75%;
            flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-flex-basis: 83.333%;
            flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-lg-11 {
    -webkit-flex-basis: 91.667%;
            flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-lg-12 {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-1 {
    margin-left: 8.333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.667%;
  }

  .start-lg {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }

  .middle-lg {
    -webkit-align-items: center;
    align-items: center;
  }

  .bottom-lg {
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }

  .around-lg {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .between-lg {
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .first-lg {
    -webkit-order: -1;
    order: -1;
  }

  .last-lg {
    -webkit-order: 1;
    order: 1;
  }
}

.flex-cols {
  display: -webkit-flex;
  display: flex;
}

.flex-one {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.center-align {
  text-align: center;
}

.right-align {
  text-align: right;
}

.title {
  font-size: 25px;
  font-weight: 700;
  padding-top: 20px;
  margin-bottom: 20px;
}

.shadow {
  box-shadow: 0 4px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
}

.smallShadow {
  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);
}

.buttonShadow {
  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);
  transition: all 0.15s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.buttonShadow:hover {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.buttonShadow:active {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);
}

@media (hover: none) {
  .buttonShadow:hover {
    -webkit-transform: none;
            transform: none;
    box-shadow: none;
  }
  .buttonShadow:target {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
    box-shadow: 0 2px 2px rgba(50, 50, 93, 0.1);
  }
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.content {
  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  color: #111;
}

.no-padding {
  padding: 0;
}

.divider {
  background: #eee;
  height: 1px;
  width: 100%;
  margin: 40px 0;
}

.loadingGlow {
  opacity: 0.4;
  -webkit-animation: loadingGlow 0.9s infinite;
          animation: loadingGlow 0.9s infinite;
}

@-webkit-keyframes loadingGlow {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes loadingGlow {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
}

.orion_button,
.orion_button:active,
.orion_button:hover {
  text-transform: none;
}

.orion_primary,
.orion_primary:active,
.orion_primary:hover {
  background-color: #0f2ff1;
}

@-webkit-keyframes loadingPulse {
  from {
    background-color: #cdcdcd;
  }
  50% {
    background-color: #e2e2e2;
  }
  to {
    background-color: #cdcdcd;
  }
}
@keyframes loadingPulse {
  from {
    background-color: #cdcdcd;
  }
  50% {
    background-color: #e2e2e2;
  }
  to {
    background-color: #cdcdcd;
  }
}

.label {
  margin-top: 20px;
  margin-bottom: 10px;
}

.label:first-child {
  margin-top: 0;
}

.autoform-field:first-child > .label {
  margin-top: 0;
}

.autoform-field:not(:first-child) > .label {
  margin-top: 20px;
}

.description {
  margin-top: 0px;
  font-size: 14px;
  color: #999999;
}

.paginated-table-items {
  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);
  background: #fff;
  border-radius: 5px;
  overflow: auto;
}

.paginated-head {
  padding: 0 0 20px;
}

.paginated-head-title > .os_container {
  padding: 0 !important;
}

.paginated-error {
  background-color: #fff;
}

.paginated-error-icon svg {
  height: 50px;
  width: 50px;
}

.paginated-head-container {
  margin-bottom: 0;
}

.paginated-head-center {
  min-height: 0px;
}

.paginated-head-right {
  height: 30px;
}

.paginated-pagination {
  margin: 0;
  padding: 20px 0;
}

.paginated-table {
  padding: 0;
}

.paginated-table table {
  width: 100%;
  border-spacing: 0;
}

.paginated-table thead {
  background-color: #f1f1f1;
  text-align: left;
}

.paginated-table tr {
  border-bottom: 1px solid #eee;
}

.paginated-table thead tr th {
  border-bottom: 1px solid #eee;
  position: relative;
  padding: 9px 10px;
  line-height: 22px;
  vertical-align: top;
  padding-top: 6px;
  padding-bottom: 6px;
  color: #32325d;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}

.paginated-table tbody tr:hover td {
  color: #111;
}

.paginated-table td,
.paginated-table th {
  white-space: nowrap;
}

.paginated-table td {
  border-bottom: 1px solid #fafafb;
  position: relative;
  padding: 9px 10px;
  color: #555;
  line-height: 22px;
  vertical-align: top;
}

.paginated-pagination-pages {
  font-size: 14px;
}

.paginated-pagination-limit {
  font-size: 14px;
}

.paginated-pagination-page-input-container {
  top: 0;
}

.paginated-pagination-page-icon,
.paginated-pagination-page-icon-disabled {
  top: 0px;
}

.paginated-pagination-page-icon {
  color: #525f7f;
}

.paginated-pagination-page-icon svg,
.paginated-pagination-page-icon-disabled svg {
  height: 22px;
  width: 22px;
}

.paginated-pagination-page-input {
  background-color: #f6f9fc;
  border-radius: 4px;
  height: 20px;
  width: 32px;
  font-size: 12px;
}

.paginated-text-field-input {
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 4px;
  padding: 4px 7px;
}

body {
  font-family: 'Titillium Web', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: #000;
  margin: 0;
  font-weight: bold;
}

h1 {
  font-size: 25px;
  padding-top: 20px;
  margin-bottom: 20px;
}

h3 {
  font-size: 20px;
}

a {
  text-decoration: none;
  color: #0f2ff1;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

.os-tabs-container {
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
}
.os-tabs-item a {
  color: #111;
}

.os-tabs-item.os-tabs-itemActive a {
  color: #0f2ff1;
  border-color: #0f2ff1;
}

.os-tabs-item {
  font-weight: bold;
}

@supports (padding: max(0px)) {
  .os-tabs-inner {
    padding-left: calc(20px + env(safe-area-inset-left));
    padding-right: calc(20px + env(safe-area-inset-right));
  }
}

.os-input-text {
  width: 100%;
  -webkit-flex: none;
          flex: none;
}

.red {
  color: #f43f4f;
}

.orange {
  color: #d45113;
}

.blue  {
  color: #0f2ff1;
}

