:local(.container) {
  composes: content from global;
}

:local(.name) {
  font-size: 18px;
}

:local(.email) {
  font-size: 14px;
  margin-bottom: 10px;
}

:local(.accessTo) {
  font-size: 14px;
  color: #999;
}

:local(.accessToAll) {
  composes: accessTo;
  margin-bottom: 22px;
}

:local(.stores) {
}

:local(.role) {
  float: right;
  background: #eee;
  border-radius: 4px;
  padding: 2px 5px;
  display: inline-block;
}
