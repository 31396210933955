:local(.container) {
  display: none;
  cursor: pointer;
  padding: 10px 18px;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 767px) {
  :local(.container) {
    display: block;
  }
}
