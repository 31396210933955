:local(.container) {
  margin-bottom: 30px;
}

:local(.line) {
  display: flex;
  padding: 3px 0;
}

:local(.line) > div:first-child {
  flex: 1;
  white-space: nowrap;
}

:local(.line) > div:last-child {
  display: block;
  margin-left: 20px;
  overflow: hidden;
}

:local(.title) {
  font-weight: bold;
  margin-bottom: 10px;
}
